<template>
  <div>
    <h4>{{ name }}</h4>
    <v-form>
      <v-container>
        <v-row class="mt-3">
          <v-col cols="8">
            <v-row class="mb-10">
              <!--Location Images-->
              <v-col cols="12">
                <gallery
                  :form="form"
                  :isSummary="true"
                  :nonPointerCursor="true"
                ></gallery>
              </v-col>
            </v-row>

            <v-row>
              <!--Location Name-->
              <v-col
                cols="12"
                class="mt-3"
                v-if="form.name !== '' && form.name !== null"
              >
                <h5>{{ form.name }}</h5>
              </v-col>
              <v-col
                cols="12"
                v-if="form.description !== '' && form.description !== null"
              >
                {{ form.description }}
              </v-col>
              <v-col
                cols="12"
                class="mt-3"
                v-if="form.address !== '' && form.address !== null"
              >
                <v-icon class="mdi mdi-map-marker"></v-icon>
                {{ form.address }}, {{ form.city }},
                {{ form.country }}
              </v-col>
              <v-col class="pt-0" cols="12" md="12" v-if="form.map_location && form.map_location.length > 0">
                <detail-view-map :latLng="form.map_location"></detail-view-map>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4" v-if="form.location_types">
                <div><label class="text--secondary">Type</label></div>
                <div class="font-weight-bold">
                  <span>{{
                    form.location_types ? form.location_types.name : ""
                  }}</span>
                </div>
              </v-col>
              <v-col cols="4" v-if="form.ptcs.length > 0">
                <div>
                  <label class="text--secondary">Public Transport</label>
                </div>
                <div class="font-weight-bold">
                  <span v-for="ptc in form.ptcs">{{ ptc.name }},</span>
                </div>
              </v-col>
              <v-col
                cols="4"
                v-if="form.vendor_specifications.total_parking_spaces"
              >
                <div><label class="text--secondary">Parking Space</label></div>
                <div class="font-weight-bold">
                  {{ form.vendor_specifications.total_parking_spaces }}
                </div>
              </v-col>
            </v-row>

            <!--            <v-row class="mt-3" v-if="form.seating_arrangements.length > 0">
                <v-col cols="12">
                  <h5>Seating arrangements</h5>
                </v-col>

                <v-col cols="12" md="12">
                  <v-item-group>
                    <v-container>
                      <v-row>
                        <v-col
                            v-for="(seatingArrangement, index) in form.seating_arrangements"
                            :key="index"
                            class="p-0 m-0 custom-item-column"
                        >
                          <v-item v-slot="{ active, toggle }" :value="seatingArrangement" disabled>
                            <v-card
                                :color="active ? 'primary' : 'default'"
                                class="d-flex align-center"
                                dark
                                height="75"
                                width="75"
                            >
                              <v-scroll-y-transition>
                                <div class="flex-grow-1 text-center black&#45;&#45;text">
                                  <small>{{ seatingArrangement.name }}</small>
                                </div>
                              </v-scroll-y-transition>
                            </v-card>
                          </v-item>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-item-group>
                </v-col>
            </v-row>-->

            <v-row class="my-3" v-if="form.vendor_spaces.length > 0">
              <v-col cols="12">
                <h5>Meeting & Event Spaces</h5>
              </v-col>
            </v-row>

            <summary-spaces
              v-if="form.vendor_spaces.length > 0"
              :spaces="form.vendor_spaces"
            ></summary-spaces>

            <div
              v-if="
                form.location_types &&
                ['Bar', 'Restaurant'].includes(form.location_types.name)
              "
            >
              <h5 class="my-3">{{ form.location_types.name }} Menu</h5>
              <menu-information :form="form"></menu-information>
            </div>

            <!--            <v-row class="mt-3" v-if="form.location_acoustic_description !== '' && form.location_acoustic_description !== null">
              <v-col cols="12">
                <label class="text&#45;&#45;secondary">Space Description</label>
              </v-col>
            </v-row>
            <v-row v-if="form.location_acoustic_description !== ''">
              <v-col cols="12">
                {{form.location_acoustic_description}}
              </v-col>
            </v-row>-->

            <!--            <v-row class="mt-3" v-if="form.event_acoustic_description !== '' && form.event_acoustic_description !== null">
              <v-col cols="12">
                <label class="text&#45;&#45;secondary">Event Acoustic Description</label>
              </v-col>
            </v-row>
            <v-row v-if="form.event_acoustic_description !== ''">
              <v-col cols="12">
                {{form.event_acoustic_description}}
              </v-col>
            </v-row>-->

            <v-row class="mt-3">
              <v-col cols="4" v-if="form.furniture.length > 0">
                <label class="text--secondary">Furniture</label>
                <div class="font-weight-bold text-wrap word-break">
                  {{ form.furniture.toString() }}
                </div>
              </v-col>
              <v-col cols="4" v-if="form.human_resources.length > 0">
                <label class="text--secondary">Human Resources</label>
                <div class="font-weight-bold text-wrap word-break">
                  {{ form.human_resources.toString() }}
                </div>
              </v-col>
              <v-col
                cols="4"
                v-if="cateringSelectedTypes(form.catering).length"
              >
                <label class="text--secondary">Catering</label>
                <div class="font-weight-bold text-wrap word-break">
                  <span
                    v-for="(catering, index) in cateringSelectedTypes(
                      form.catering
                    )"
                    v-if="catering.selected"
                  >
                    <span v-if="index != 0">, </span>{{ catering.name }}
                  </span>
                </div>
              </v-col>
            </v-row>

            <v-row
              class="mt-3"
              v-if="getOpeningHours(form.opening_hours).length > 0"
            >
              <v-col cols="12">
                <label class="text--secondary">Opening Hours</label>
              </v-col>
            </v-row>
            <v-row
              v-if="getOpeningHours(form.opening_hours).length > 0"
              v-for="(opening_hour, index) in form.opening_hours"
              :key="'oh' + index"
            >
              <v-col cols="6" class="text-left">
                {{ opening_hour.day }}
              </v-col>
              <v-col cols="6" class="text-right">
                <span v-if="!opening_hour.is_open">Close</span>
                <span v-else
                  >{{ opening_hour.start_time }} -
                  {{ opening_hour.end_time }}</span
                >
              </v-col>
            </v-row>

            <v-row class="mt-3" v-if="form.seasonal_timings.length">
              <v-col cols="12">
                <label class="text--secondary">Opening Seasons</label>
              </v-col>
            </v-row>
            <v-row
              v-for="(seasonal_timing, index) in form.seasonal_timings"
              :key="'st' + index"
            >
              <v-col cols="6" class="text-left"> Date </v-col>
              <v-col cols="6" class="text-right">
                {{ seasonal_timing.start_date }} -
                {{ seasonal_timing.end_date }}
              </v-col>
            </v-row>

            <v-row class="mt-3" v-if="form.event_types.length > 0">
              <v-col cols="12">
                <label class="text--secondary">Type of Events</label>
              </v-col>
            </v-row>
            <v-row v-if="form.event_types.length > 0">
              <v-col cols="12">
                <v-item-group>
                  <v-container>
                    <v-row>
                      <v-col
                        v-for="(eventType, index) in form.event_types"
                        :key="index"
                        class="p-0 m-0 custom-item-column"
                      >
                        <v-item v-slot="{ active, toggle }" disabled>
                          <v-card
                            :color="active ? 'primary' : 'default'"
                            class="d-flex align-center"
                            dark
                            height="75"
                            width="75"
                          >
                            <v-scroll-y-transition>
                              <div class="text-center flex-grow-1 black--text">
                                <v-icon
                                  color="black"
                                  :class="eventType.icon"
                                ></v-icon>
                                <div>
                                  <small>{{ eventType.name }}</small>
                                </div>
                              </div>
                            </v-scroll-y-transition>
                          </v-card>
                        </v-item>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-item-group>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="4"
            class="bg-color-grey"
            v-if="locationEquipments.length > 0"
          >
            <label class="font-weight-bold">Equipments</label>
            <v-item-group
              v-for="(equipment, index) in form.equipments"
              :key="'eq' + index"
            >
              <v-list-item v-if="equipment.selected">
                <label :class="{ 'text--secondary': !equipment.selected }"
                  ><i :class="equipment.icon"></i> {{ equipment.name }}</label
                >
              </v-list-item>
            </v-item-group>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-btn
      color="primary"
      class="float-right mt-5 mr-3"
      v-if="getIsVendorUpdate && getVendor && getVendor.status === 'active'"
      @click="submit('save')"
      >Update Venue</v-btn
    >
    <v-btn
      color="primary"
      class="float-right mt-5 mr-3"
      v-if="getIsVendorUpdate && getVendor && getVendor.status !== 'active'"
      @click="submit('draft')"
      >Update Venue</v-btn
    >
    <v-btn
      color="primary"
      class="float-right mt-5 mr-3"
      v-if="!getIsVendorUpdate"
      @click="submit('draft')"
      >Create Venue</v-btn
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
const Gallery = () =>
  import(/* webpackChunkName: "gallery" */ "@/components/Gallery");
const SummarySpaces = () =>
  import(
    /* webpackChunkName: "summary-spaces" */ "@/components/sections/SummarySpaces"
  );
const MenuInformation = () =>
  import(
    /* webpackChunkName: "menu-information" */ "@/components/sections/MenuInformation"
  );
const DetailViewMap = () =>
    import(
        /* webpackChunkName: "detail-view-map" */ "@/components/DetailViewMap"
        );
export default {
  name: "LocationSummary",
  props: ["name", "form"],
  components: { Gallery, SummarySpaces, MenuInformation, DetailViewMap },
  data: () => ({}),
  computed: {
    drafts() {
      return _.filter(this.getVendors, { status: "draft" });
    },
    locationEquipments() {
      return _.filter(this.form.equipments, { selected: true });
    },
    ...mapGetters(["getVendors", "getIsVendorUpdate", "getVendor"]),
  },
  methods: {
    moment,
    cateringSelectedTypes(cateringTypes = []) {
      return _.filter(cateringTypes, (cateringType) => {
        if (cateringType.selected === true) {
          return cateringType;
        }
      });
    },
    getOpeningHours(openingHours = []) {
      return _.filter(openingHours, (openingHour) => {
        if (
          openingHour.start_time !== "00:00" ||
          openingHour.end_time !== "00:00"
        ) {
          return openingHour;
        }
      });
    },
    async submit(type = "draft") {
      this.$emit("submit", type);
    },
  },
};
</script>
<style scoped>
.custom-item-column {
  padding: 7px !important;
  flex-grow: 0 !important;
}
.bg-color-grey {
  background-color: #f9f9f9;
}
.carousel-custom-style {
  border-radius: 20px;
}
.margin {
  margin: 5px;
}
.custom-draft-style {
  width: 100%;
}
.image-container {
  max-width: 100px;
  max-height: 100px;
  height: 100px;
  width: 120px;
  background-color: gray;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.custom-card-checkbox {
  position: absolute;
  z-index: 1;
  top: -15px;
  left: 5px;
}
</style>
<style>
.word-break {
  word-wrap: break-word;
}
</style>
